.musicPlayer {
  position: absolute;
  width: 400px;
  bottom: 20px;
}

.playerImage {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
}

.screen {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 50px;
  background-color: rgba(0, 0, 0, 1);
  color: #36d16e;
  text-align: center;
  line-height: 30px;
  font-size: 0.5rem;
  border-radius: 5px;
  z-index: 0;
  overflow: hidden;
  text-shadow: var(--main-text-shadow);
}

.marquee {
  display: block;
  width: 105%;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.marquee div {
  display: inline-block;
  padding-right: 90%; /* Added padding-right */
  animation: marquee 6s linear infinite; /* Reduced duration */
}

@keyframes marquee {
  0% {
    transform: translateX(45%); /* Start outside the right edge */
  }
  100% {
    transform: translateX(-50%); /* Move to outside the left edge */
  }
}

.playPauseButton {
  position: absolute;
  top: 60px;
  right: 215px;
  width: 50px;
  height: 25px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1;
}

.nextButton {
  position: absolute;
  top: 60px;
  right: 95px;
  width: 50px;
  height: 25px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1;
}

.genreButton {
  position: absolute;
  top: 60px;
  right: 135px;
  width: 70px;
  height: 25px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1;
}

.player5Image {
  position: absolute;
  width: 25px;
  height: 0px;
  bottom: 60px;
  right: 95px;
  z-index: 2;
  transform-origin: center center; // Apply to the parent container

  & > img {
    transform-origin: center center; // Apply to the image itself
    animation-fill-mode: forwards;
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 1023px) {
  .musicPlayer {
    position: relative;
    width: 400px;
    margin-right: 4rem;
    transform: translateY(20px);
  }

  .playerImage {
    width: 100%;
    height: auto;
  }

  .playPauseButton {
    top: 60px;
    right: 215px;
    height: 20px;
  }

  .nextButton {
    top: 60px;
    right: 95px;
    height: 20px;
  }

  .genreButton {
    top: 60px;
    right: 135px;
    height: 20px;
  }

  .player5Image {
    width: 25px;
    height: 0px;
    bottom: 60px;
    right: 95px;
    & > img {
      width: 30px;
      height: 30px;
    }
  }

  .screen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 215px;
    height: 50px;
    font-size: 0.6rem;
  }
}

@media (min-width: 1400px) {
  .musicPlayer {
    width: 500px;
    margin-right: 7rem;
  }

  .playPauseButton {
    top: 60px;
    right: 275px;
    top: 75px;
    width: 55px;
  }

  .nextButton {
    top: 75px;
    right: 130px;
    width: 55px;
  }

  .genreButton {
    right: 60px;
    top: 75px;
    right: 178px;
    width: 80px;
  }
  .player5Image {
    width: 25px;
    height: 0px;
    bottom: 70px;
    right: 125px;
    & > img {
      width: 40px;
      height: 40px;
    }
  }
}

@media (min-width: 1900px) {
  .musicPlayer {
    width: 600px;
    margin-right: 9rem;
  }

  .playPauseButton {
    top: 90px;
    height: 30px;
    right: 335px;
    width: 60px;
  }

  .nextButton {
    top: 90px;
    height: 30px;
    right: 155px;
    width: 70px;
  }

  .genreButton {
    top: 90px;
    height: 30px;
    right: 215px;
    width: 95px;
  }

  .screen {
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    height: 75px;
    line-height: 30px;
    font-size: 0.8rem;
  }

  .player5Image {
    width: 25px;
    height: 0px;
    bottom: 80px;
    right: 150px;
    & > img {
      width: 50px;
      height: 50px;
    }
  }
}
