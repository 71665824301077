/* Mixin for Flexbox Centering */
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 5px;
  width: 100%;
  max-width: 375px;
  margin: 0.5rem auto;
  z-index: 2;

  &__logo-container,
  &__buttons {
    @include flex-center;
    position: relative;
  }

  &__logo-container {
    width: 100%;
    justify-content: left;
  }

  &__button,
  &__power-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;

    .icon {
      margin: 0 5px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  &__power-button .icon {
    width: 60px;
    height: 60px;
  }

  &__logo {
    position: absolute;
    width: 100px;
    height: 100px;
    object-fit: contain;
    right: -90px;
  }
}

/* LAPTOP AND TABLET */
@media (min-width: 1024px) {
  .header {
    justify-content: space-between;
    padding: 0 10px;
    max-width: 1024px;

    &__logo-container {
      width: 20%;
    }

    &__button .icon,
    &__power-button .icon {
      width: 55px;
      height: 55px;
    }

    &__logo {
      right: 80px;
      top: 75px;
    }

    &__power-button {
      margin-left: 3.5rem;

      .icon {
        width: 75px;
        height: 75px;
      }
    }

    &__button {
      margin-right: 1rem;
      // transform: translateX(-100px);
    }

    &__music-player {
      display: block;
    }
  }
}

/* SMALL DESKTOP */
@media (min-width: 1400px) {
  .header {
    max-width: 1400px;

    &__button .icon,
    &__power-button .icon {
      width: 60px;
      height: 60px;
    }

    &__power-button {
      margin-left: 6.5rem;

      .icon {
        width: 85px;
        height: 85px;
      }
    }

    &__logo-container {
      width: 22%;
    }

    &__logo {
      width: 150px;
      height: 150px;
      right: 200px;
      bottom: -200px;
    }

    &__button {
      margin-right: 1rem;
      transform: translateX(-80px);
    }
  }
}

/* LARGE DESKTOP */
@media (min-width: 1900px) {
  .header {
    padding: 0 20px;
    max-width: 1725px;

    &__button .icon,
    &__power-button .icon {
      width: 70px;
      height: 70px;
    }

    &__power-button {
      margin-right: 8rem;

      .icon {
        width: 95px;
        height: 95px;
      }
    }

    &__logo-container {
      width: 21%;
    }

    &__logo {
      width: 200px;
      height: 200px;
      bottom: -265px;
    }
  }
}
