// src/components/Loader/Loader.module.scss
.loader {
  border: 4px solid rgba(0, 255, 0, 0.2);
  border-left-color: #00ff00;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
