/* src/app/components/ai-door-background/DoorComponent.module.scss */

.door {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 99999;
  pointer-events: none;

  &--close {
    pointer-events: all;
    z-index: 99999;
  }

  &--open {
    .left-door {
      animation: openLeftDoor 4s forwards;
      animation-fill-mode: forwards;
    }

    .right-door {
      animation: openRightDoor 4s forwards;
      animation-fill-mode: forwards;
    }
  }
}

.left-door,
.right-door {
  width: 50vw;
  height: 100%;
  object-fit: cover; // Ensure the doors maintain their aspect ratio
}

.left-door {
  transform: translateX(-65vw); /* Adjust the initial position */
}

.right-door {
  transform: translateX(65vw); /* Adjust the initial position */
}

.left-door--close {
  animation: closeLeftDoor 5.4s forwards;
  animation-fill-mode: forwards;
}

.right-door--close {
  animation: closeRightDoor 5.4s forwards;
  animation-fill-mode: forwards;
}

@keyframes closeLeftDoor {
  0% {
    transform: translateX(-65vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes closeRightDoor {
  0% {
    transform: translateX(65vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes openLeftDoor {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-65vw);
  }
}

@keyframes openRightDoor {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(65vw);
  }
}

// Adjustments for mobile screens
@media (max-width: 600px) {
  .left-door,
  .right-door {
    width: 60vw; // Increase the width to reduce the squished appearance
  }

  .left-door {
    transform: translateX(-60vw);
  }

  .right-door {
    transform: translateX(60vw);
  }

  @keyframes closeLeftDoor {
    0% {
      transform: translateX(-60vw);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes closeRightDoor {
    0% {
      transform: translateX(60vw);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes openLeftDoor {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-60vw);
    }
  }

  @keyframes openRightDoor {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(60vw);
    }
  }
}
