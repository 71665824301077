/* Include the font in your global styles or import a similar one */
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

/* SCSS Styles for SnakeGame */
.gameContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 300px;
  width: 300px;
  z-index: 99999999999999999;
  position: relative;
  font-family: 'VT323', monospace;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px;
  position: relative;
  margin-top: 1.6rem;
}

.startButton,
.tabButton {
  width: 80px;
  padding: 5px;
  margin: 0 5px;
  color: var(--primary-color);
  background: transparent;
  border: 2px solid var(--primary-color);
  cursor: pointer;
  font-size: var(--font-size-xxs);
  text-shadow: var(--main-text-shadow);
  font-family: 'VT323', monospace;
  animation: glitch 1s infinite;
}

.startButton.active,
.tabButton.active {
  background: var(--primary-color);
  color: #000;
}

.startButton:active,
.tabButton:active,
.startButton:focus,
.tabButton:focus {
  background: var(--primary-color);
  border-color: var(--primary-color);
  outline: none;
  color: #000;
}

.startButton {
  position: absolute;
  bottom: -200px;
  right: 25px;
  font-size: var(--font-size-xxxs);
  transform: rotate(-35deg);
  width: 55px;
  padding: 5px;
}

.canvas {
  border: 1px solid var(--primary-color);
  background: #000;
}

.gamePad {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2px;

  button {
    padding: 5px 10px;
    margin: 2px;
    color: var(--primary-color);
    background: transparent;
    border: 2px solid var(--primary-color);
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: var(--font-size-xxxs);
    text-shadow: var(--main-text-shadow);
    font-family: 'VT323', monospace;
  }

  div {
    display: flex;
    justify-content: center;
    margin: 1px 0;

    button:nth-child(1) {
      margin-right: 16px;
    }

    button:nth-child(2) {
      margin-left: 16px;
    }
  }
}

.score {
  color: var(--primary-color);
  font-size: var(--font-size-xs);
  text-shadow: var(--main-text-shadow);
  font-family: 'VT323', monospace;
  animation: glitch 1s infinite;
  position: absolute;
  bottom: -80px;
  left: 35px;
}

@media (min-width: 1023px) {
  .gameContainer {
    height: 400px;
    width: 400px;
  }

  .canvas {
    width: 285px;
    height: 285px;
  }

  .buttonContainer {
    margin-top: 1.2rem;
  }

  .gamePad {
    display: none;
  }

  .score {
    font-size: var(--font-size-lg);
    bottom: -155px;
    left: 0px;
  }

  .startButton {
    position: absolute;
    bottom: -330px;
    right: 120px;
    font-size: var(--font-size-sm);
    transform: rotate(0deg);
    width: 150px;
  }

  .tabButton {
    width: 145px;
    font-size: var(--font-size-sm);
  }
}

@media (min-width: 1400px) {
  .gameContainer {
    height: 450px;
    width: 450px;
  }

  .buttonContainer {
    margin-top: 0.6rem;
  }

  .canvas {
    width: 350px;
    height: 350px;
  }

  .startButton {
    position: absolute;
    bottom: -400px;
    right: 145px;
    font-size: var(--font-size-md);
    transform: rotate(0deg);
    width: 150px;
  }

  .tabButton {
    width: 170px;
    font-size: var(--font-size-md);
  }

  .score {
    font-size: var(--font-size-xl);
    bottom: -190px;
    left: -15px;
  }
}

@media (min-width: 1900px) {
  .gameContainer {
    height: 600px;
    width: 600px;
  }

  .canvas {
    width: 450px;
    height: 450px;
  }

  .startButton {
    position: absolute;
    bottom: -525px;
    right: 190px;
    font-size: var(--font-size-md);
    transform: rotate(0deg);
    width: 200px;
  }

  .tabButton {
    width: 220px;
    font-size: var(--font-size-md);
  }

  .score {
    font-size: var(--font-size-xxl);
    bottom: -250px;
    left: 0px;
  }
}
