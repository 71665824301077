/* Mixin for Flexbox Centering */
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* MOBILE */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  @include flex-center;
  flex-direction: column;
  background-color: #000;
  z-index: 1000;
  transition: z-index 0.3s ease-in-out;
  font-family: 'JetBrains Mono', monospace;

  &__progress-container {
    @include flex-center;
    flex-direction: column;
    width: 250px;
  }

  &__progress-text {
    align-self: flex-start;
    position: relative;
    font-size: 20px;
    color: #38d06e;
    font-weight: bold;
    text-shadow:
      0 0 10px rgba(56, 208, 110, 0.8),
      0 0 20px rgba(56, 208, 110, 0.6),
      0 0 30px rgba(56, 208, 110, 0.4),
      0 0 40px rgba(56, 208, 110, 0.2);

    &::before,
    &::after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background: black;
      color: #38d06e;
    }

    &::before {
      left: 2px;
      text-shadow:
        -2px 0 red,
        2px 2px blue,
        -2px -2px yellow;
      animation:
        glitch-animation-1 1s infinite linear alternate-reverse,
        glitch-colors 2s infinite linear;
    }

    &::after {
      left: -2px;
      text-shadow:
        -2px 0 blue,
        2px 2px red,
        -2px -2px green;
      animation:
        glitch-animation-2 1s infinite linear alternate-reverse,
        glitch-colors 2s infinite linear;
    }
  }

  &__progress-bar {
    box-sizing: border-box;
    width: 250px;
    height: 35px;
    border: 2px solid #38d06e;
    filter: drop-shadow(0px 0px 12px #38d06e);
    position: relative;
    overflow: hidden;
    animation:
      glitch-animation-1 1.5s infinite linear alternate-reverse,
      glitch-colors 2s infinite linear;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background: #1b1c1b;
    }

    &::before {
      left: 2px;
      text-shadow:
        -2px 0 red,
        2px 2px blue,
        -2px -2px yellow;
      animation:
        glitch-animation-1 1s infinite linear alternate-reverse,
        glitch-colors 2s infinite linear;
    }

    &::after {
      left: -2px;
      text-shadow:
        -2px 0 blue,
        2px 2px red,
        -2px -2px green;
      animation:
        glitch-animation-2 1s infinite linear alternate-reverse,
        glitch-colors 2s infinite linear;
    }
  }

  &__progress-bar-segments {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;
    position: absolute;
    width: 200px;
    height: 25px;
    left: 5px;
    top: 5px;
  }

  &__progress-segment {
    width: 2px;
    height: 25px;
    background: #38d06e;
    box-shadow: 0px 0px 16px #38d06e;
    flex: none;
    flex-grow: 0;
    transform: translate(-3px, -2px);
  }

  &__progress-bar-background {
    position: absolute;
    width: 250px;
    height: 35px;
    left: calc(100%);
    top: calc(50% - 35px / 2);
    background: #1b1c1b;
  }
}

/* LAPTOP AND TABLET */
@media (min-width: 1023px) {
  .loader {
    width: 100vw;
    height: 100vh;
    font-family: 'JetBrains Mono', monospace;

    &__progress-container {
      width: 410px;
    }

    &__progress-bar {
      width: 410px;
    }

    &__progress-bar-segments {
      width: 400px;
    }

    &__progress-segment {
      width: 4px;
    }

    &__progress-bar-background {
      width: 335px;
    }
  }
}

@keyframes glitch-animation-1 {
  0% {
    clip: rect(44px, 9999px, 56px, 0);
  }
  10% {
    clip: rect(20px, 9999px, 30px, 0);
  }
  20% {
    clip: rect(80px, 9999px, 90px, 0);
  }
  30% {
    clip: rect(40px, 9999px, 50px, 0);
  }
  40% {
    clip: rect(60px, 9999px, 70px, 0);
  }
  50% {
    clip: rect(50px, 9999px, 60px, 0);
  }
  60% {
    clip: rect(30px, 9999px, 40px, 0);
  }
  70% {
    clip: rect(70px, 9999px, 80px, 0);
  }
  80% {
    clip: rect(10px, 9999px, 20px, 0);
  }
  90% {
    clip: rect(90px, 9999px, 100px, 0);
  }
  100% {
    clip: rect(44px, 9999px, 56px, 0);
  }
}

@keyframes glitch-animation-2 {
  0% {
    clip: rect(10px, 9999px, 20px, 0);
  }
  10% {
    clip: rect(50px, 9999px, 60px, 0);
  }
  20% {
    clip: rect(30px, 9999px, 40px, 0);
  }
  30% {
    clip: rect(70px, 9999px, 80px, 0);
  }
  40% {
    clip: rect(20px, 9999px, 30px, 0);
  }
  50% {
    clip: rect(60px, 9999px, 70px, 0);
  }
  60% {
    clip: rect(40px, 9999px, 50px, 0);
  }
  70% {
    clip: rect(80px, 9999px, 90px, 0);
  }
  80% {
    clip: rect(10px, 9999px, 20px, 0);
  }
  90% {
    clip: rect(90px, 9999px, 100px, 0);
  }
  100% {
    clip: rect(10px, 9999px, 20px, 0);
  }
}

@keyframes glitch-colors {
  0% {
    background-position: 0% 0%;
  }
  10% {
    background-position: 10% 10%;
  }
  20% {
    background-position: 20% 20%;
  }
  30% {
    background-position: 30% 30%;
  }
  40% {
    background-position: 40% 40%;
  }
  50% {
    background-position: 50% 50%;
  }
  60% {
    background-position: 60% 60%;
  }
  70% {
    background-position: 70% 70%;
  }
  80% {
    background-position: 80% 80%;
  }
  90% {
    background-position: 90% 90%;
  }
  100% {
    background-position: 100% 100%;
  }
}
