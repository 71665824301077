// _mixins.scss

@mixin scrollbar-styles {
  // For Webkit browsers (Chrome, Safari, etc.)
  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: black;
  }
  &::-webkit-scrollbar-button {
    background-color: var(--primary-color);
  }
  &::-webkit-scrollbar-corner {
    background-color: black;
  }

  // For Firefox
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) black;

  // For Internet Explorer
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-base-color: var(--primary-color);
  scrollbar-face-color: var(--primary-color);
  scrollbar-3dlight-color: var(--primary-color);
  scrollbar-highlight-color: var(--primary-color);
  scrollbar-track-color: black;
  scrollbar-arrow-color: var(--primary-color);
  scrollbar-shadow-color: var(--primary-color);
  scrollbar-dark-shadow-color: black;
}
