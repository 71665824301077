:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: 'JetBrains Mono', ui-monospace, Menlo, Monaco, 'Cascadia Mono',
    'Segoe UI Mono', 'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace',
    'Source Code Pro', 'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-color: #36d16e;
  --secondary-color: #fafafa;

  --main-text-shadow: 0 0 5px rgba(54, 209, 110, 0.2),
    0 0 10px rgba(54, 209, 110, 0.2), 0 0 21.7926px rgba(54, 209, 110, 0.2);

  --font-size-xxxs: 0.4rem;
  --font-size-xxs: 0.5rem;
  --font-size-xs: 0.75rem;
  --font-size-sm: 1rem;
  --font-size-md: 1.25rem;
  --font-size-lg: 1.5rem;
  --font-size-xl: 2rem;
  --font-size-xxl: 2.5rem;
  --font-size-xxxl: 3rem;

  --button-padding-sm: 0.2rem 0.8rem;
  --button-padding-md: 0.4rem 1rem;
  --button-padding-lg: 0.6rem 1.25rem;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
  html::-webkit-scrollbar,
  body::-webkit-scrollbar {
    display: none;
  }
  font-family: var(--font-mono);
  color: var(--primary-color);
}

html,
body {
  max-width: 100vw;
}

html {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

body {
  overflow-x: hidden;
  font-family: var(--font-mono);
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  user-drag: none;
}
