@import '../../mixins';
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nft {
  display: flex;
  flex-direction: column;
  height: 225px;
  width: 265px;
  mix-blend-mode: difference;
  z-index: 2;
  overflow: scroll;
  overflow-x: hidden;
  @include scrollbar-styles;
  &__title {
    margin: 0.35rem 0;
    color: var(--primary-color);
    font-size: var(--font-size-xs);
  }

  &__paragraph {
    margin-bottom: 10px;
    white-space: pre-line;
    line-height: 1.6;
    font-size: var(--font-size-xxs);
    color: var(--secondary-color);
    padding: 0 8px;
  }

  &__list {
    margin-bottom: 10px;
    padding-left: 0.5rem;
    font-size: var(--font-size-xxs);
    color: var(--secondary-color);

    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 5px;
      list-style: none;
      position: relative;
      padding-left: 12px;

      .nft__list-icon {
        width: 8px;
        height: 8px;
        position: absolute;
        left: 0;
        top: 2px;
      }
    }
  }

  @media (min-width: 1023px) {
    height: 350px;
    width: 375px;

    &__title {
      margin: 0.45rem 0;
      font-size: var(--font-size-sm);
    }

    &__paragraph,
    &__list {
      font-size: var(--font-size-xs);

      li {
        margin-bottom: 5px;
        padding-left: 14px;

        .nft__list-icon {
          width: 10px;
          height: 10px;
          left: 0;
          top: 3.5px;
        }
      }
    }
  }

  @media (min-width: 1400px) {
    height: 400px;
    width: 450px;

    &__title {
      font-size: var(--font-size-md);
    }

    &__paragraph,
    &__list {
      font-size: 0.95rem;

      li {
        margin-bottom: 5px;
        padding-left: 16px;

        .nft__list-icon {
          width: 12px;
          height: 12px;
          left: 0;
          top: 6px;
        }
      }
    }
  }

  @media (min-width: 1900px) {
    height: 545px;
    width: 600px;

    &__title {
      margin: 0.65rem 0;
      font-size: var(--font-size-md);
    }

    &__paragraph,
    &__list {
      font-size: var(--font-size-md);
      margin-bottom: 1rem;
      li {
        margin-bottom: 12px;
        padding-left: 24px;

        .nft__list-icon {
          width: 16px;
          height: 16px;
          left: 0;
          top: 7px;
        }
      }
    }
  }
}
