/* Mixin for Flexbox Centering */
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.memes__animate-button-placeholder {
  margin-top: 0.3rem;
  width: 125px;
  padding: var(--button-padding-sm);
  border: 1px solid transparent;
  font-size: var(--font-size-xxxs);
  background: none;
  color: transparent;
  cursor: default;
  align-self: center;
  text-shadow: none;
  font-weight: 700;
}

@media (min-width: 1023px) {
  .memes__animate-button-placeholder {
    width: 150px;
    font-size: var(--font-size-xs);
  }
}

@media (min-width: 1400px) {
  .memes__animate-button-placeholder {
    width: 175px;
    font-size: var(--font-size-sm);
  }
}

@media (min-width: 1900px) {
  .memes__animate-button-placeholder {
    width: 235px;
    font-size: var(--font-size-md);
  }
}

/* MOBILE */
.memes {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 235px;
    width: 260px;
    position: relative;
    margin: 0;
    background: transparent;
    z-index: 2;
  }

  &__hidden {
    display: none;
  }

  &__media-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 235px;
    width: 260px;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(
      0,
      0,
      0,
      0.5
    ); /* Optional: Add a background to indicate loading */
  }

  &__no-zoom {
    font-size: var(--font-size-md);
  }

  &__back-button {
    position: absolute;
    top: 5px;
    left: 10px;
    background: none;
    border: none;
    font-size: var(--font-size-lg);
    padding: 0.3rem;
    cursor: pointer;
    z-index: 15;
  }

  &__image-container {
    @include flex-center;
    position: relative;
  }

  &__top-text,
  &__bottom-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: var(--secondary-color);
    font-size: 0.7rem;
    text-shadow:
      -1px -1px 2px #000,
      1px -1px 2px #000,
      -1px 1px 2px #000,
      1px 1px 2px #000;
    padding: 0.1rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    width: 90%;
    text-align: center;
    font-family: 'Arial';
    font-weight: 900;
    // padding: 0rem 0.6rem;
  }

  &__top-text {
    top: 0;
  }

  &__bottom-text {
    bottom: 3%;
    padding-bottom: 0.3rem;
  }

  &__thumbnails-container {
    @include flex-center;
    width: 250px;
    height: 300px;
    padding: 0.2 0rem;
  }

  &__arrow-button {
    background: none;
    border: none;
    font-size: var(--font-size-lg);
    cursor: pointer;
    text-shadow: var(--main-text-shadow);
    margin: 0 0.4rem;
    height: 50px;
    width: 50px;
  }

  &__thumbnails {
    @include flex-center;
  }

  &__thumbnail {
    min-width: 75px;
    margin: 0 0.2rem;
    border: 1px solid var(--primary-color);
    @include flex-center;
    cursor: pointer;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__buttons-container {
    display: flex;
    gap: 0.5rem;
  }

  &__randomize-button,
  &__add-button,
  &__generate-button,
  &__create-button {
    margin-top: 0.3rem;
    width: 125px;
    padding: var(--button-padding-sm);
    border: 1px solid var(--primary-color);
    font-size: var(--font-size-xxxs);
    background: none;
    color: var(--primary-color);
    cursor: pointer;
    align-self: center;
    text-shadow: var(--main-text-shadow);
    font-weight: 700;
  }

  &__create-button {
    background-color: var(--primary-color);
    color: #1e1e1e;
    font-weight: 700;
  }

  &__buttons-generate {
    display: flex;
    flex-direction: row;
    transform: translateY(-10px);
    width: 100%;
    gap: 1rem;
  }

  &__generate-button {
    margin-top: 0.3rem;
    width: 125px;
    padding: var(--button-padding-sm);
    border: 1px solid var(--primary-color);
    font-size: var(--font-size-xxxs);
    background: none;
    color: var(--primary-color);
    cursor: pointer;
    align-self: center;
    text-shadow: var(--main-text-shadow);
    font-weight: 700;
  }

  &__lottie-animation {
    width: 100%; /* Adjust width to match the button width */
    height: 9px; /* Adjust height to match the text size */
  }

  &__text-inputs-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin-top: 0rem;
    position: relative;
    gap: 0.1rem;
  }

  &__text-input {
    padding: 0.2rem 0.2rem;
    margin: 0;
    border: 1px solid var(--primary-color);
    background: none;
    color: var(--primary-color);
    font-size: 16px;
    transform: scale(0.65);
    transform-origin: 0 0;
    width: 154%;
    box-sizing: border-box;

    &::placeholder {
      color: var(--primary-color);
      font-size: 10px;
    }
  }

  &__text-input:nth-of-type(2) {
    transform-origin: 0 -12px;
  }

  &__action-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__animate-button {
    @include flex-center;
    padding: var(--button-padding-md);
    border: 1px solid var(--primary-color);
    background: none;
    color: var(--primary-color);
    cursor: pointer;
    font-size: var(--font-size-xxxs);
    width: 100%;
    text-shadow: var(--main-text-shadow);
    font-weight: 700;
  }

  &__main-image,
  &__main-video {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  &__loading-spinner {
    width: 8px;
    margin-left: 8px;
  }

  &__save-button,
  &__share-button {
    @include flex-center;
    padding: var(--button-padding-md);
    border: 1px solid var(--primary-color);
    background: none;
    color: var(--primary-color);
    cursor: pointer;
    font-size: var(--font-size-xxxs);
    width: 48%;
    text-shadow: var(--main-text-shadow);
    font-weight: 700;
    margin-top: 0.4rem;
  }

  &__loading-spinner {
    width: 8px;
    margin-left: 16px;
  }

  &__create-another-button {
    padding: var(--button-padding-sm);
    border: 1px solid var(--primary-color);
    font-weight: 700;
    background: none;
    color: #1e1e1e;
    font-style: bold;
    width: 100%;
    font-size: var(--font-size-xxxs);
    position: relative;
    overflow: hidden;
    clip-path: polygon(5% 0, 100% 0, 100% 50%, 95% 100%, 0 100%, 0 50%);
    background-color: var(--primary-color);
    cursor: pointer;
    transform: translateY(-6px);
    text-shadow: var(--main-text-shadow);
    margin-top: 0.6rem;
  }

  &__or-line {
    @include flex-center;
    width: 100%;
    margin: 1rem 0;
    color: var(--primary-color);
    text-shadow: var(--main-text-shadow);
  }

  &__text-input:focus {
    outline: none;
    border-color: var(--primary-color);
    background: none;
    color: var(--primary-color);
  }

  @media (min-width: 1023px) {
    &__container {
      max-height: 350px;
      width: 400px;
      transform: translateY(0px) translateX(-6px);
    }

    &__back-button {
      top: 5px;
      left: 12px;
      font-size: var(--font-size-xxl);
    }

    &__arrow-button {
      font-size: var(--font-size-xxl);
      margin: 0 0.4rem;
    }

    &__thumbnails-container {
      height: 300px;
      padding: 0.8rem 0rem;
    }

    &__thumbnails {
      margin: 0 0.3rem;
    }

    &__thumbnail {
      width: 120px;
      min-width: 120px;
      height: 70px;
      min-height: 70px;
    }

    &__randomize-button,
    &__add-button,
    &__generate-button {
      padding: var(--button-padding-sm);
      font-size: var(--font-size-xs);
      width: 150px;
      border: 1px solid var(--primary-color);
      background: none;
      color: var(--primary-color);
      cursor: pointer;
      text-shadow: var(--main-text-shadow);
      font-weight: 700;
    }

    &__text-inputs-container {
      width: 100%;
      height: 300px;
      gap: 0.5rem;
    }

    &__text-input {
      padding: var(--button-padding-md);
      font-size: var(--font-size-sm);
      transform: none;
      width: 100%;
      margin-left: 0;
      margin-top: 0.1rem;
    }

    &__buttons-generate {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      transform: translateY(0px);
      width: 100%;
      gap: 1rem;
    }

    &__create-button {
      font-size: var(--font-size-xs);
      width: 175px;
      background-color: var(--primary-color);
      color: #1e1e1e;
      font-weight: 700;
    }

    &__generate-button {
      width: 175px;
    }

    &__lottie-animation {
      width: 100%; /* Adjust width to match the button width */
      height: 16px; /* Adjust height to match the text size */
    }

    &__action-buttons {
      width: 100%;
      // transform: translateY(5px);
    }

    &__animate-button {
      padding: var(--button-padding-md);
      font-size: var(--font-size-xs);
      font-weight: 700;
    }

    &__save-button,
    &__share-button {
      font-size: var(--font-size-xs);
      margin: 0.6rem 0rem;
      border: 1px solid var(--primary-color);
      background: none;
      color: var(--primary-color);
      cursor: pointer;
      text-shadow: var(--main-text-shadow);
      font-weight: 700;
      padding: var(--button-padding-md);
      margin-top: 0rem;
    }

    &__create-another-button {
      font-size: var(--font-size-xs);
      padding: var(--button-padding-md);
      border: 1px solid var(--primary-color);
      background: none;
      color: #1e1e1e;
      cursor: pointer;
      font-weight: 700;
      background-color: var(--primary-color);
      text-shadow: var(--main-text-shadow);
      margin: 0;
    }

    &__top-text,
    &__bottom-text {
      font-size: 1rem;
      // padding: 0rem 1.5rem;
    }

    &__main-image,
    &__main-video {
      width: 100%;
      height: 230px;
      object-fit: cover;
    }
  }

  @media (min-width: 1400px) {
    &__container {
      max-height: 425px;
      width: 485px;
    }

    &__back-button {
      top: 5px;
      left: 20px;
      font-size: var(--font-size-xxxl);
    }

    &__arrow-button {
      font-size: var(--font-size-xxxl);
    }

    &__thumbnails-container {
      height: 300px;
      padding: 0.2rem 0rem;
    }

    &__thumbnails {
      margin: 1rem 1rem;
    }

    &__thumbnail {
      width: 135px;
      min-width: 135px;
      height: 90px;
      min-height: 90px;
    }

    &__randomize-button,
    &__add-button,
    &__generate-button {
      padding: var(--button-padding-md);
      font-size: var(--font-size-sm);
      width: 175px;
      border: 1px solid var(--primary-color);
      background: none;
      color: var(--primary-color);
      cursor: pointer;
      text-shadow: var(--main-text-shadow);
      font-weight: 700;
      margin-top: 0;
    }

    &__randomize-button,
    &__add-button {
      transform: translateY(-6px);
    }

    &__text-inputs-container {
      width: 100%;
      height: 300px;
      gap: 0.6rem;
      margin-top: 0rem;
    }

    &__text-input {
      padding: var(--button-padding-md);
      font-size: var(--font-size-md);
      margin-top: 0.1rem;
    }

    &__action-buttons {
      width: 100%;
    }

    &__animate-button {
      padding: var(--button-padding-md);
      font-size: var(--font-size-sm);
    }
    &__save-button,
    &__share-button {
      font-size: var(--font-size-sm);
      border: 1px solid var(--primary-color);
      background: none;
      color: var(--primary-color);
      cursor: pointer;
      text-shadow: var(--main-text-shadow);
      font-weight: 700;
      padding: var(--button-padding-lg);
    }

    &__create-another-button {
      font-size: var(--font-size-sm);
      padding: var(--button-padding-lg);
      border: 1px solid var(--primary-color);
      background: none;
      color: #1e1e1e;
      cursor: pointer;
      font-weight: 700;
      background-color: var(--primary-color);
      text-shadow: var(--main-text-shadow);
      margin: 0;
    }

    &__top-text,
    &__bottom-text {
      font-size: 1.2rem;
      // padding: 0rem 1.5rem;
    }

    &__buttons-generate {
      display: flex;
      flex-direction: row;
      transform: translateY(0px);
      width: 100%;
      gap: 1rem;
    }

    &__create-button {
      font-size: var(--font-size-sm);
      width: 275px;
      padding: var(--button-padding-md);
      background-color: var(--primary-color);
      color: #1e1e1e;
      font-weight: 700;
      margin-top: 0;
    }

    &__generate-button {
      font-size: var(--font-size-sm);
      width: 275px;
      padding: var(--button-padding-md);
    }

    &__lottie-animation {
      width: 100%; /* Adjust width to match the button width */
      height: 20px; /* Adjust height to match the text size */
    }

    &__main-image,
    &__main-video {
      width: 100%;
      height: 275px;
    }
  }

  @media (min-width: 1900px) {
    &__container {
      max-height: 575px;
      width: 640px;
      transform: translate(-10px);
    }

    &__back-button {
      top: 5px;
      left: 20px;
      font-size: var(--font-size-xxxl);
    }

    &__arrow-button {
      font-size: var(--font-size-xxxl);
    }

    &__thumbnails-container {
      height: 300px;
    }

    &__thumbnails {
      margin: 1rem 1rem;
    }

    &__thumbnail {
      width: 175px;
      min-width: 175px;
      height: 100px;
      min-height: 100px;
    }

    &__randomize-button,
    &__add-button,
    &__generate-button {
      padding: var(--button-padding-lg);
      font-size: var(--font-size-md);
      width: 235px;
      border: 1px solid var(--primary-color);
      background: none;
      color: var(--primary-color);
      cursor: pointer;
      text-shadow: var(--main-text-shadow);
      font-weight: 700;
    }

    &__text-inputs-container {
      width: 100%;
      height: 400px;
    }

    &__text-input {
      padding: 0.2rem 0.2rem;
      margin: 0.3rem 0;
      border: 1px solid var(--primary-color);
      background: none;
      color: var(--primary-color);
      font-size: var(--font-size-lg);
      box-sizing: border-box;

      &::placeholder {
        color: var(--primary-color);
        font-size: var(--font-size-sm);
      }
    }

    &__top-text,
    &__bottom-text {
      font-size: 1.6rem;
      padding: 0rem 0rem;
    }

    &__buttons-generate {
      display: flex;
      flex-direction: row;
      transform: translateY(0px);
      width: 100%;
      gap: 1rem;
    }

    &__generate-button {
      font-size: var(--font-size-md);
      padding: var(--button-padding-lg);
      margin: 0.8rem 0 0.6rem 0;
      border: 1px solid var(--primary-color);
      background: none;
      color: var(--primary-color);
      cursor: pointer;
      text-shadow: var(--main-text-shadow);
      font-weight: 700;
    }

    &__create-button {
      font-size: var(--font-size-md);
      width: 275px;
      padding: var(--button-padding-lg);
      background-color: var(--primary-color);
      color: #1e1e1e;
      font-weight: 700;
    }

    &__generate-button {
      font-size: var(--font-size-md);
      width: 275px;
      padding: var(--button-padding-lg);
    }

    &__action-buttons {
      width: 100%;
    }

    &__animate-button {
      padding: var(--button-padding-md);
      font-size: var(--font-size-lg);
    }
    &__save-button,
    &__share-button {
      font-size: var(--font-size-lg);
      border: 1px solid var(--primary-color);
      background: none;
      color: var(--primary-color);
      cursor: pointer;
      text-shadow: var(--main-text-shadow);
      font-weight: 700;
    }

    &__loading-spinner {
      width: 16px;
      margin-left: 16px;
    }

    &__create-another-button {
      font-size: var(--font-size-lg);
      padding: var(--button-padding-md);
      border: 1px solid var(--primary-color);
      background: none;
      color: #1e1e1e;
      cursor: pointer;
      font-weight: 700;
      background-color: var(--primary-color);
      text-shadow: var(--main-text-shadow);
    }

    &__main-image,
    &__main-video {
      width: 100%;
      height: 360px;
      object-fit: cover;
    }
  }
}
