@import '../../mixins';

.home {
  display: flex;
  flex-direction: column;
  height: 225px;
  width: 265px;
  mix-blend-mode: difference;
  z-index: 2;
  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    white-space: pre-wrap;
  }

  &__prompt-title {
    font-size: var(--font-size-xxs);
    color: var(--secondary-color);
    width: 100%;
    padding: 0rem 0.2rem;
    margin: 0.6rem 0 0.7rem 0;
  }

  &__prompt-description {
    font-size: var(--font-size-xxs);
    height: 120px;
    max-height: 120px;
    overflow-y: scroll;
    line-height: 1.6;
    padding: 0rem 0.2rem;
    text-shadow: var(--main-text-shadow);
    width: 100%;
    overflow-y: scroll;
    @include scrollbar-styles;
  }

  &__placeholder {
    font-size: var(--font-size-xxs);
    margin: 0.8rem 0;
    color: var(--secondary-color);
  }

  &__prompts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.3rem;
    overflow-y: scroll; // Ensure scrollbar is always visible
    color: var(--secondary-color);
  }

  &__prompt-button {
    border: 0.1px solid var(--secondary-color);
    color: var(--secondary-color);
    padding: var(--button-padding-sm);
    cursor: pointer;
    font-size: var(--font-size-xxxs);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s;
    text-align: center;

    &--disabled {
      border-color: var(--primary-color);
    }

    &:hover {
      border: 1px solid var(--primary-color);
    }

    & img {
      height: 5px;
      width: 5px;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: center; // Center the button inside the wrapper
  }

  &__button-container {
    display: inline-flex;
    margin-top: 0.5rem;
    & img {
      margin-left: 5px;
    }
  }

  &__button {
    border: 0.1px solid var(--secondary-color);
    background-color: var(--primary-color);
    color: #000;
    padding: var(--button-padding-sm);
    cursor: pointer;
    font-size: var(--font-size-xxxs);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s;
    text-align: center;
    margin: 0 auto;
    width: 150%;
    font-weight: 700;
    &--disabled {
      border-color: var(--primary-color);
    }

    &:hover {
      border: 1px solid var(--secondary-color);
    }

    & img {
      height: 5px;
      width: 5px;
    }
  }
}

@media (min-width: 1023px) {
  .home {
    height: 350px;
    width: 375px;

    &__prompts {
      gap: 0.5rem;
    }

    &__prompt-title {
      font-size: var(--font-size-xs);
      margin: 1rem 0;
    }

    &__prompt-description {
      height: 215px;
      max-height: 215px;
      font-size: var(--font-size-xs);
    }

    &__placeholder {
      font-size: var(--font-size-xs);
      margin: 1rem 0;
    }

    &__prompt-button,
    &__button {
      padding: var(--button-padding-sm);
      font-size: var(--font-size-xs);
      transform: translateY(0px);

      & img {
        height: 5px;
        width: 5px;
      }
    }
  }
}

@media (min-width: 1400px) {
  .home {
    height: 400px;
    width: 450px;

    &__prompts {
      gap: 0.6rem;
    }

    &__prompt-title {
      font-size: var(--font-size-sm);
      margin: 1rem 0;
    }

    &__prompt-description {
      height: 225px;
      max-height: 225px;
      font-size: var(--font-size-sm);
    }

    &__placeholder {
      font-size: var(--font-size-sm);
      margin: 1rem 0;
    }

    &__prompt-button,
    &__button {
      padding: var(--button-padding-md);
      font-size: var(--font-size-xs);
      transform: translateY(0px);

      & img {
        height: 8px;
        width: 8px;
      }
    }

    &__prompts {
      gap: 0.6rem;
    }
    &__button-container {
      margin-top: 0.75rem;
    }
  }
}

@media (min-width: 1900px) {
  .home {
    height: 545px;
    width: 600px;

    &__prompt-title {
      font-size: var(--font-size-md);
      margin: 1.5rem 0;
    }

    &__prompt-description {
      height: 325px;
      max-height: 325px;
      font-size: var(--font-size-md);
    }

    &__placeholder {
      font-size: var(--font-size-md);
      margin: 1.5rem 0;
    }

    &__prompt-button,
    &__button {
      font-size: var(--font-size-sm);
      transform: translateY(0px);
      padding: var(--button-padding-lg);
    }

    &__prompts {
      gap: 1rem;
    }

    &__button-container {
      margin-top: 1rem;
    }
  }
}

/* Styling scrollbars for webkit browsers */
.home__prompt-description::-webkit-scrollbar {
  width: 10px;
}

.home__prompt-description::-webkit-scrollbar-track {
  background: var(--secondary-color);
}

.home__prompt-description::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 5px;
  border: 3px solid var(--secondary-color);
}

.home__prompts::-webkit-scrollbar {
  width: 10px;
}

.home__prompts::-webkit-scrollbar-track {
  background: var(--secondary-color);
}

.home__prompts::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 5px;
  border: 3px solid var(--secondary-color);
}
