/* Mixin for Flexbox Centering */
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* MOBILE */
.footer {
  @include flex-center;
  flex-direction: column;
  background-color: transparent;
  z-index: 2;
  margin-bottom: 2rem;

  &__sticker-dark-matter,
  &__sticker-chad,
  &__sticker-ai-dumb,
  &__sticker-borderlands-header,
  &__sticker-red-text,
  &__sticker-ball,
  &__sticker-poster,
  &__kill-ai img,
  &__kill-sticker {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.footer__kill-ai {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.footer__brain {
  width: 350px;
  margin-left: 0.5rem;
  position: relative;
}

.footer__brain-animation {
  position: absolute;
  top: 39px;
  right: 90px;
  width: 53.1%;
  height: 62.6%;
}

.footer__brain-image {
  width: 100%;
}

.footer__brain-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer__sticker-dark-matter {
  width: 135px;
  margin-bottom: 1.5rem;
}

.footer__sticker-container-kill {
  position: relative;
}

.footer__kill-ai {
  width: 150px;
  transform: translate(20px, 10px);
}

.footer__kill-sticker {
  position: absolute;
  width: 100px;
  bottom: -70px;
  left: 26px;
}

.footer__sticker-container {
  display: flex;
  flex-wrap: nowrap;
}

.footer__sticker-container-left {
  position: relative;
}

.footer__sticker-chad,
.footer__sticker-ai-dumb,
.footer__sticker-borderlands-header,
.footer__sticker-red-text,
.footer__sticker-ball,
.footer__sticker-poster {
  width: 125px;
}

.footer__sticker-ai-dumb {
  width: 225px;
  position: absolute;
  left: -15px;
  bottom: 20%;
  z-index: 1;
  pointer-events: none;
}

.footer__sticker-borderlands-header {
  transform: translate(30px, 0);
  width: 100px;
}

.footer__sticker-red-text {
  width: 100px;
  transform: translateY(-60px);
  z-index: 1;
}

.footer__sticker-ball {
  width: 75px;
  transform: translate(70px, -130px);
  z-index: 2;
}

.footer__sticker-poster {
  width: 150px;
  transform: translate(-25px, -105px);
}

.footer__sticker-container-right {
  display: flex;
  flex-direction: column;
}

/* LAPTOP AND TABLET */
@media (min-width: 1023px) {
  .footer__brain {
    width: 600px;
    transform: translateX(10px);
  }

  .footer__brain-animation {
    top: 66px;
    right: 155.3px;
    width: 53%;
    height: 62.6%;
  }

  .footer__kill-ai {
    display: none;
  }

  .footer__kill-sticker {
    display: none;
  }

  .footer__sticker-container {
    width: 1046px;
    @include flex-center;
  }

  .footer__sticker-container-left,
  .footer__sticker-container-right {
    position: relative;
    height: 200px;
    width: 100%;
  }

  .footer__sticker-chad {
    width: 184px;
    position: absolute;
    left: 35px;
    top: -220px;
  }

  .footer__sticker-ai-dumb {
    width: 375px;
    position: absolute;
    left: 50px;
    top: -45px;
  }

  .footer__sticker-borderlands-header {
    position: absolute;
    width: 200px;
    bottom: 425px;
    left: 300px;
  }

  .footer__sticker-red-text {
    position: absolute;
    width: 200px;
    bottom: 200px;
    left: 300px;
  }

  .footer__sticker-ball {
    position: absolute;
    width: 125px;
    top: -25px;
    left: 350px;
  }

  .footer__sticker-poster {
    position: absolute;
    width: 200px;
    bottom: 0;
    left: 185px;
  }

  .footer__sticker-dark-matter {
    width: 275px;
  }
}

/* SMALL DESKTOP */
@media (min-width: 1400px) {
  .footer__brain {
    width: 700px;
    transform: translateX(0px);
  }

  .footer__brain-animation {
    top: 77px;
    right: 183px;
    width: 52.8%;
    height: 62.6%;
  }

  .footer__sticker-dark-matter {
    width: 300px;
  }

  .footer__kill-ai {
    display: none;
  }

  .footer__sticker-container {
    width: 1400px;
    @include flex-center;
  }

  .footer__sticker-container-left,
  .footer__sticker-container-right {
    position: relative;
    height: 200px;
    width: 100%;
  }

  .footer__sticker-chad {
    width: 200px;
    position: absolute;
    left: 115px;
    top: -300px;
  }

  .footer__sticker-ai-dumb {
    width: 400px;
    position: absolute;
    left: 130px;
    top: -105px;
  }

  .footer__sticker-borderlands-header {
    position: absolute;
    width: 200px;
    bottom: 450px;
    left: 355px;
  }

  .footer__sticker-red-text {
    position: absolute;
    width: 200px;
    bottom: 225px;
    left: 350px;
  }

  .footer__sticker-ball {
    position: absolute;
    width: 125px;
    top: -60px;
    left: 410px;
  }

  .footer__sticker-poster {
    position: absolute;
    width: 200px;
    bottom: 20px;
    left: 275px;
  }
}

/* LARGE DESKTOP */
@media (min-width: 1900px) {
  .footer__brain {
    width: 900px;
  }

  .footer__brain-animation {
    top: 100px;
    right: 228.7px;
    width: 53.7%;
    height: 63%;
  }

  .footer__sticker-dark-matter {
    width: 350px;
    transform: translateX(-10px);
  }

  .footer__kill-ai {
    display: none;
  }

  .footer__sticker-container {
    width: 1900px;
    @include flex-center;
  }

  .footer__sticker-container-left,
  .footer__sticker-container-right {
    position: relative;
    height: 200px;
    width: 100%;
  }

  .footer__sticker-chad {
    width: 300px;
    position: absolute;
    left: 150px;
    top: -315px;
  }

  .footer__sticker-ai-dumb {
    width: 350px;
    position: absolute;
    left: 250px;
    top: -50px;
  }

  .footer__sticker-borderlands-header {
    position: absolute;
    width: 350px;
    bottom: 500px;
    left: 500px;
  }

  .footer__sticker-red-text {
    position: absolute;
    width: 275px;
    bottom: 250px;
    left: 500px;
  }

  .footer__sticker-ball {
    position: absolute;
    width: 175px;
    top: -100px;
    left: 600px;
  }

  .footer__sticker-poster {
    position: absolute;
    width: 275px;
    bottom: -50px;
    left: 350px;
  }
}
