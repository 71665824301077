.tvScreen {
  position: relative;
  width: 85%;
  height: 83%;
  background: #181818;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
  z-index: 1;
  overflow: hidden;
  margin: 0 auto;
  transform: translateX(-4px);
  background-image: url('/backgrounds/background.webp');

  &::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: radial-gradient(
      circle at center,
      rgba(0, 0, 0, 0) 15%,
      rgba(0, 0, 0, 0.5) 60%,
      rgba(0, 0, 0, 0.8) 100%
    );
    pointer-events: none;
    z-index: 1;
    transform: scale(1.5);
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.glitchOverlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-size:
    5px 5px,
    5px 5px;
  background-position:
    -1px -1px,
    -1px -1px;
  background-image: linear-gradient(
      rgba(217, 217, 217, 0.01) 1px,
      transparent 1px
    ),
    linear-gradient(90deg, rgba(217, 217, 217, 0.01) 0.3px, transparent 1px);
  mix-blend-mode: difference;
  pointer-events: none;
}

.screenOverlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('/terminal-keys/screen-overlay-black.webp');
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none;

  img {
    width: 100%;
    height: 100%;
    z-index: 9999999999;
  }
}
