/* src/app/components/center/Center.module.scss */

.center {
  &__frame-image,
  &__floppy-socket,
  &__connect-wallet-button img,
  &__chadbot-row--label,
  &__chadbot-button img,
  &__control-button img,
  &__controls-background,
  &__kill-ai-button img,
  &__kill-ai-sticker {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  &__control-button,
  &__chadbot-button,
  &__kill-ai-button {
    position: relative;
    overflow: hidden;

    .rest-image,
    .pressed-image {
      position: absolute;
      top: 0;
      left: 0;
    }

    .pressed-image {
      opacity: 0;
    }

    &:active .rest-image {
      opacity: 0;
    }

    &:active .pressed-image {
      opacity: 1;
    }
  }

  &__container {
    display: grid;
    grid-template-areas:
      'tvFrame'
      'walletRow'
      'chadbotRow'
      'controlsRow';
    align-items: center;
    justify-items: center;
    width: 100%;
    min-width: 375px;
  }

  &__tv-frame {
    grid-area: tvFrame;
    position: relative;
    height: 320px;
    min-width: 375px;
    max-width: 375px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(4px);
  }

  &__frame-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
    object-fit: contain;
  }

  &__wallet-row {
    grid-area: walletRow;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 1.5rem 0;
  }

  &__chadbot-row {
    grid-area: chadbotRow;
    display: flex;
    justify-content: space-between;
    max-width: 375px;
    margin: 0 auto;
    gap: 10px;
    padding: 1rem;
    position: relative;
  }
  &__chadbot-row--label {
    height: 20px;
    position: absolute;
    top: -10px;
    right: 0px;
  }

  &__chadbot-controls {
    position: absolute;
    height: 30px;
    width: 200px;
    top: -15px;
    right: 88px;
  }

  &__controls-row {
    grid-area: controlsRow;
    display: grid;
    grid-template-columns: repeat(4, 2fr);
    gap: 10px;
    max-width: 375px;
    margin: 1rem auto;
    width: 100%;
    position: relative;
  }

  &__controls-container {
    display: contents;
    position: relative;
  }

  &__controls-background {
    position: absolute;
    top: -20px;
    left: -13px;
    width: 400px;
    height: 150px;
    z-index: 0;
  }

  &__control-button,
  &__chadbot-button,
  &__connect-wallet-button,
  &__kill-ai-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &__control-button {
    width: 75px;
    height: 41px;
    margin: 5px;
    z-index: 1;
  }

  &__chadbot-button {
    width: 100px;
    height: 35px;
  }

  &__floppy-socket {
    width: 225px;
    height: 35px;
    object-fit: contain;
  }

  &__connect-wallet-button {
    width: 100px;
    height: 35px;

    &:hover {
      cursor: pointer;
    }
  }

  &__kill-ai-button {
    display: none;

    &:hover {
      cursor: pointer;
    }
  }

  &__kill-ai-sticker {
    display: none;
  }

  @media (min-width: 390px) {
    &__tv-frame {
      height: 331px;
      min-width: 385px;
      max-width: 385px;
      transform: translateX(3px);
    }
  }

  @media (min-width: 414px) {
    &__tv-frame {
      height: 343px;
      min-width: 400px;
      max-width: 400px;
      transform: translateX(0px);
    }
  }

  @media (min-width: 428px) {
    &__tv-frame {
      height: 354px;
      min-width: 420px;
      max-width: 420px;
      transform: translateX(1px);
    }

    &__chadbot-label {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    &__container {
      grid-template-areas:
        'leftContainer tvFrame rightContainer'
        'leftContainer tvFrame rightContainer'
        'killAI bottomContainer -';
      max-width: 1023px;
      margin: 0 auto;
    }

    &__wallet-row {
      grid-area: bottomContainer;
    }

    &__chadbot-row {
      grid-area: rightContainer;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 1rem;
      width: 200px;
      transform: translateX(0px);
    }

    &__chadbot-row--label {
      height: 30px;
      position: absolute;
      top: -30px;
      right: 0px;
    }

    &__control-button {
      width: 100px;
      height: 100%;
      margin: 0;
    }

    &__controls-row {
      grid-area: leftContainer;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      align-content: center;
      width: 200px;
      gap: 10px;
      margin-right: 1.5rem;
    }

    &__chadbot-button {
      width: 175px;
      height: 100%;
    }

    &__tv-frame {
      height: 500px;
      min-width: 580px;
    }

    &__floppy-socket {
      width: 375px;
      height: 75px;
    }

    &__connect-wallet-button {
      width: 150px;
      height: 50px;
    }

    &__kill-ai-container {
      grid-area: killAI;
      position: relative;
      margin-bottom: 50px;
    }

    &__kill-ai-button {
      position: absolute;
      display: flex;
      width: 200px;
      bottom: -100px;
      left: -85px;
    }

    &__kill-ai-sticker {
      position: absolute;
      display: flex;
      width: 150px;
      bottom: -195px;
      left: -85px;
    }
    &__chadbot-label {
      position: absolute;
      display: block;
      height: 25px;
      object-fit: cover;
      top: -40px;
    }
  }

  @media (min-width: 1400px) {
    &__container {
      max-width: 1400px;
    }

    &__control-button {
      width: 125px;
      height: 100%;
      margin: 0;
    }

    &__chadbot-row {
      grid-area: rightContainer;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 1rem;
      width: 200px;
      transform: translateX(-40px);
    }

    &__chadbot-row--label {
      height: 40px;
      position: absolute;
      top: -50px;
      right: 7px;
    }

    &__controls-row {
      grid-area: leftContainer;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      align-content: center;
      width: 200px;
      gap: 20px;
      margin-right: 0rem;
      transform: translateX(-75px);
    }

    &__chadbot-button {
      width: 200px;
      height: 100%;
    }

    &__tv-frame {
      width: 700px;
      height: 600px;
      max-width: 700px;
      max-height: 600px;
    }

    &__floppy-socket {
      width: 450px;
      height: 90px;
    }

    &__connect-wallet-button {
      width: 175px;
      height: 70px;
    }

    &__kill-ai-button {
      width: 200px;
      bottom: -100px;
      left: -70px;
    }

    &__kill-ai-sticker {
      width: 150px;
      bottom: -195px;
      left: -70px;
    }

    &__chadbot-label {
      height: 30px;
      top: -45px;
    }
  }

  @media (min-width: 1900px) {
    &__container {
      max-width: 1900px;
    }

    &__control-button {
      width: 150px;
      height: 100%;
      margin: 0;
    }

    &__controls-row {
      grid-area: leftContainer;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      align-content: center;
      width: 200px;
      gap: 20px;
      transform: translateX(-100px);
    }

    &__chadbot-row {
      grid-area: rightContainer;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 1rem;
      width: 200px;
      transform: translateX(-95px);
    }

    &__chadbot-row--label {
      height: 50px;
      position: absolute;
      top: -80px;
      right: 0px;
    }

    &__chadbot-button {
      width: 250px;
      height: 100%;
    }

    &__tv-frame {
      width: 900px;
      min-width: 900px;
      height: 800px;
      max-height: 800px;
      max-width: 800px;
    }

    &__floppy-socket {
      width: 450px;
      height: 90px;
    }

    &__connect-wallet-button {
      width: 175px;
      height: 70px;
    }

    &__kill-ai-button {
      width: 250px;
      bottom: -100px;
      left: -45px;
    }

    &__kill-ai-sticker {
      width: 180px;
      bottom: -220px;
      left: -45px;
    }

    &__chadbot-label {
      height: 35px;
      top: -60px;
    }
  }
}
