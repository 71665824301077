// src/components/modal/GlassMorphicModal.module.scss

.modal {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 7000; // Ensure it is above other elements
    overflow: scroll;
    opacity: 0; // Start with invisible
    transition: opacity 1s ease-in-out; // Add fade-in transition
  }

  &__overlay--visible {
    opacity: 1; // Make visible with fade-in effect
    animation: fadeIn 1s ease-in-out; // Add fade-in animation
  }

  &__content {
    background: radial-gradient(
      192.15% 192.15% at 50% 50%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.02) 19.05%,
      #000 100%
    );
    border-radius: 10px;
    padding: 20px; // Ensure padding for mobile
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: #fafafa;
    text-align: left;
    max-width: 750px;
    width: 90%; // Ensure responsiveness
    font-family: 'Courier New', Courier, monospace;
    line-height: 1.5; // Adjusted line height
    height: 100%;
    overflow: scroll;
    opacity: 1; // Ensure content opacity is always 1

    h2 {
      margin-bottom: 10px;
      color: #00ff00;
    }

    h3 {
      margin-top: 20px;
      margin-bottom: 10px;
      color: #00ff00;
    }

    p {
      margin-bottom: 20px;
      white-space: pre-line;
    }

    .button-container {
      display: flex;
      justify-content: center; // Center the single button
      margin-top: 20px;
    }

    .custom-button {
      background: transparent;
      border: 2px solid #00ff00;
      border-radius: 5px;
      padding: 10px 20px;
      color: #00ff00;
      cursor: pointer;
      font-size: 16px;
      margin: 0 5px;
      font-family: 'JetBrains Mono', monospace;
      font-weight: 700;

      &:hover {
        background: rgba(0, 255, 0, 0.1);
      }
    }
  }

  @media (min-width: 1023px) {
    &__content {
      height: auto;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
