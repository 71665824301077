/* src/app/page.module.scss */

.page {
  &__container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  &__main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    overflow: auto;
    background-image: url('/backgrounds/background.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-width: 375px;
    overflow-x: hidden;
  }
}

.loadingContainer {
  position: relative;
  text-align: center;
  width: 100%;
  height: 60%;
}

.loadingGif {
  width: 100%; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
}

.loadingText {
  position: absolute;
  bottom: -25px;
  width: 100%;
  color: #36d16e;
  font-size: 12px;
  text-align: center; /* Ensure the text is centered */
  font-weight: 700;
}

.loadingDots {
  display: inline-block;
  width: 1.5em; /* Fixed width for 3 dots */
  text-align: left; /* Align text to the left within the container */
  &::after {
    content: ' ';
    animation: dots 1.5s steps(5, end) infinite;
  }
}

@keyframes dots {
  0%,
  20% {
    content: ' ';
  }
  40% {
    content: '.';
  }
  60% {
    content: '..';
  }
  80%,
  100% {
    content: '...';
  }
}

@media (min-width: 1023px) {
  .loadingText {
    font-size: 16px;
  }
}

@media (min-width: 1400px) {
  .loadingText {
    font-size: 20px;
  }
}

@media (min-width: 1900px) {
  .loadingText {
    font-size: 20px;
  }
}
