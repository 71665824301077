.header {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-xxxs);
  align-items: center;
  text-shadow: var(--main-text-shadow);
  margin: 0 8px;

  @media (min-width: 414px) {
    font-size: var(--font-size-xxxs);
  }

  @media (min-width: 1023px) {
    font-size: var(--font-size-xs);
  }

  @media (min-width: 1400px) {
    font-size: var(--font-size-xs);
  }

  @media (min-width: 1900px) {
    font-size: var(--font-size-sm);
  }
}
