@import url('https://fonts.googleapis.com/css?family=JetBrains+Mono|Fira+Mono:400');

@keyframes blinker {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.coming-soon__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 1rem 1.75rem;
  z-index: 2;
  position: relative;
  overflow: hidden;
}

.coming-soon__version {
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
  line-height: 172.3%;
  text-shadow: var(--main-text-shadow);
  position: absolute;
}

.coming-soon__text-top {
  position: absolute;
  font-size: var(--font-size-xxxs);
  top: 465px;
}

.coming-soon__text {
  font-size: var(--font-size-md);
  color: var(--primary-color);
  animation: blinker 4s step-end infinite;
  font-style: normal;
  line-height: 172.3%;
  text-shadow: var(--main-text-shadow);
  position: relative;
  font-weight: 700;
}

@media (min-width: 1023px) {
  .coming-soon__container {
    padding: 1.5rem 2.5rem;
  }

  .coming-soon__version {
    top: 25px;
    font-size: var(--font-size-xxl);
  }

  .coming-soon__text {
    font-size: var(--font-size-lg);
  }

  .coming-soon__text-top {
    font-size: var(--font-size-xxs);
    top: 415px;
  }
}

@media (min-width: 1400px) {
  .coming-soon__container {
    padding: 1.5rem 3.5rem;
  }

  .coming-soon__version {
    top: 35px;
    font-size: var(--font-size-xl);
  }

  .coming-soon__text {
    font-size: var(--font-size-xl);
  }

  .coming-soon__text-top {
    font-size: var(--font-size-xxs);
    top: 385px;
  }
}

@media (min-width: 1900px) {
  .coming-soon__container {
    padding: 1.5rem 4.5rem;
  }

  .coming-soon__version {
    top: 35px;
    font-size: var(--font-size-xxxl);
  }

  .coming-soon__text {
    font-size: var(--font-size-xxl);
    margin: 1.4rem 0;
    padding: 0.1rem;
  }

  .coming-soon__text-top {
    font-size: var(--font-size-xs);
    top: 300px;
  }
}
