@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp {
  display: flex;
  flex-direction: column;
  height: 225px;
  width: 265px;
  mix-blend-mode: difference;
  z-index: 2;
  overflow: scroll;

  &__title {
    margin: 0.35rem 0;
    color: var(--primary-color);
    font-size: var(--font-size-xs);
  }

  &__paragraph {
    margin-bottom: 10px;
    white-space: pre-line;
    line-height: 1.6;
    font-size: var(--font-size-xxs);
    color: var(--secondary-color);
  }

  &__link {
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
  }

  @media (min-width: 1023px) {
    height: 350px;
    width: 375px;

    &__title {
      margin: 0.45rem 0;
      font-size: var(--font-size-sm);
    }

    &__paragraph {
      font-size: var(--font-size-xs);
    }
  }

  @media (min-width: 1400px) {
    height: 400px;
    width: 450px;

    &__title {
      font-size: var(--font-size-md);
    }

    &__paragraph {
      font-size: var(--font-size-sm);
    }
  }

  @media (min-width: 1900px) {
    height: 545px;
    width: 600px;

    &__title {
      margin: 0.65rem 0;
      font-size: var(--font-size-md);
    }

    &__paragraph {
      font-size: var(--font-size-md);
      margin: 0.4rem;
    }
  }
}
