@keyframes blinker {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.coming-soon__overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  background-size:
    5px 5px,
    5px 5px;
  background-position:
    -1px -1px,
    -1px -1px;
  background-image: linear-gradient(
      rgba(217, 217, 217, 0.01) 1px,
      transparent 1px
    ),
    linear-gradient(90deg, rgba(217, 217, 217, 0.01) 0.3px, transparent 1px);
  mix-blend-mode: difference;
  z-index: 2;
}

.coming-soon__container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  align-items: center;
  justify-items: center;
  height: 100vh;
  text-align: center;
  background-color: black;
  padding: 2rem;
  height: 100%;
  width: 100%;
  background: radial-gradient(
    192.15% 192.15% at 50% 50%,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.0199473) 19.05%,
    #000000 100%
  );
  margin: 0 auto;
  transform: translateX(-10px);
}

.coming-soon__version {
  font-size: var(--font-size-xxxs);
  margin-top: 1rem;
  transform: translateY(-30px);
  text-shadow: var(--main-text-shadow);
}

.coming-soon__grid {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-template-rows: repeat(3, auto);
  gap: 1rem;
  justify-content: center;
  align-content: center;
  justify-items: center;
  text-shadow: var(--main-text-shadow);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -105%);
}

.coming-soon__item {
  font-size: var(--font-size-xs);
  width: 70px;
}

.coming-soon__row {
  display: contents;
}

.coming-soon__start-text {
  font-size: var(--font-size-xs);
  line-height: 1.6;
  text-transform: capitalize;
  text-shadow: var(--main-text-shadow);
  animation: blinker 4s step-end infinite;
}

.coming-soon__backed-by {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  text-shadow: var(--main-text-shadow);
  z-index: 999;
  margin-top: 1.85rem;
}

.coming-soon__backed-by-text {
  font-size: var(--font-size-xs);
  text-shadow: var(--main-text-shadow);
}

.coming-soon__logos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.6rem;
  object-fit: contain;

  &--second-row {
    grid-template-columns: repeat(4, 1fr);
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}

@media (min-width: 414px) {
  .coming-soon__container {
    transform: translateX(-10px);
  }

  .coming-soon__logos {
    gap: 0.6rem;
    div {
      width: 65px;
      height: 65px;
    }
  }
}

@media (min-width: 428px) {
  .coming-soon__container {
    transform: translateX(-8px);
  }

  .coming-soon__logos {
    gap: 0.6rem;
    div {
      width: 70px;
      height: 70px;
    }
  }
}

@media (min-width: 1023px) {
  .coming-soon__version {
    font-size: var(--font-size-xxxs);
    margin-top: 1.25rem;
  }

  .coming-soon__start-text {
    font-size: var(--font-size-xs);
    line-height: 1.6;
    margin: 1rem;
  }

  .coming-soon__backed-by {
    margin-bottom: 1rem;
    margin-top: 2.5rem;
  }

  .coming-soon__backed-by-text {
    font-size: var(--font-size-xs);
  }

  .coming-soon__logos {
    gap: 0.6rem;
    div {
      width: 100px;
      height: 100px;
    }
  }
}

@media (min-width: 1400px) {
  .coming-soon__version {
    font-size: var(--font-size-xxs);
    margin-top: 2rem;
  }

  .coming-soon__start-text {
    font-size: var(--font-size-sm);
    line-height: 1.6;
    margin: 1.8rem;
  }

  .coming-soon__backed-by {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  .coming-soon__backed-by-text {
    font-size: var(--font-size-sm);
  }

  .coming-soon__logos {
    gap: 0.6rem;
    div {
      width: 120px;
      height: 120px;
    }
  }
}

@media (min-width: 1900px) {
  .coming-soon__version {
    font-size: var(--font-size-xs);
    margin-top: 2rem;
  }

  .coming-soon__start-text {
    font-size: var(--font-size-md);
    line-height: 1.6;
    margin: 3rem;
  }

  .coming-soon__backed-by {
    margin-bottom: 1rem;
    margin-top: 2.5rem;
  }

  .coming-soon__backed-by-text {
    font-size: var(--font-size-md);
  }

  .coming-soon__logos {
    gap: 0.6rem;
    div {
      width: 160px;
      height: 160px;
    }
  }
}
