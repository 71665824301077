/* src/app/components/ai-door-background/AIDoorBackground.module.scss */

.ai-door-background {
  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    background-blend-mode: overlay;
    z-index: 6000; /* Ensure it is above the doors when they are open */
    display: none;

    &--open {
      display: block; /* Show when doors are opening */
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
