.highScoresContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items at the start */
  height: 300px;
  width: 300px;
  color: var(--primary-color);
  padding: 10px;
  box-sizing: border-box;
  font-family: var(--font-mono);
  text-shadow: var(--main-text-shadow);

  h2 {
    margin-bottom: 10px;
    font-size: var(--font-size-sm);
    position: relative;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: left;
    font-size: var(--font-size-xs);
    margin-top: 10px; /* Add some margin to separate from the header */

    li {
      padding: 5px 0;
      border-bottom: 1px solid var(--primary-color);
      display: flex;
      justify-content: space-between;
    }
  }
}

@media (min-width: 1023px) {
  .highScoresContainer {
    height: 400px;
    width: 400px;
    margin-top: 1rem;

    h2 {
      margin-bottom: 10px;
      font-size: var(--font-size-xl);
      position: relative;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      text-align: left;
      font-size: var(--font-size-md);
      margin-top: 10px; /* Add some margin to separate from the header */

      li {
        padding: 5px 0;
        border-bottom: 1px solid var(--primary-color);
        display: flex;
        justify-content: space-between;
      }
    }
  }

  @media (min-width: 1400px) {
    .highScoresContainer {
      height: 400px;
      width: 400px;
      margin-top: 1rem;

      h2 {
        margin-bottom: 10px;
        font-size: var(--font-size-xl);
        position: relative;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        text-align: left;
        font-size: var(--font-size-lg);
        margin-top: 10px; /* Add some margin to separate from the header */

        li {
          padding: 5px 0;
          border-bottom: 1px solid var(--primary-color);
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  @media (min-width: 1900px) {
    .highScoresContainer {
      height: 600px;
      width: 600px;
      margin-top: 1rem;

      h2 {
        margin-bottom: 10px;
        font-size: var(--font-size-xxl);
        position: relative;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        text-align: left;
        font-size: var(--font-size-xl);
        margin-top: 10px; /* Add some margin to separate from the header */

        li {
          padding: 5px 0;
          border-bottom: 1px solid var(--primary-color);
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
