@import '../../mixins';
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.staking {
  display: flex;
  flex-direction: column;
  height: 225px;
  width: 265px;
  mix-blend-mode: difference;
  z-index: 2;
  overflow: scroll;
  overflow-x: hidden;
  @include scrollbar-styles;

  &__title {
    margin: 0.35rem 0;
    color: var(--primary-color);
    font-size: var(--font-size-xs);
  }

  &__paragraph {
    margin-bottom: 10px;
    white-space: pre-line;
    line-height: 1.6;
    font-size: var(--font-size-xxs);
    color: var(--secondary-color);
    padding: 0 8px;
  }

  &__list {
    margin-bottom: 10px;
    padding-left: 0.2rem;
    font-size: var(--font-size-xxs);
    color: var(--secondary-color);

    li {
      margin-bottom: 5px;
      list-style: none;
    }
  }

  &__link {
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
  }

  @media (min-width: 1023px) {
    height: 350px;
    width: 375px;

    &__title {
      margin: 0.45rem 0;
      font-size: var(--font-size-sm);
    }

    &__paragraph,
    &__list {
      font-size: var(--font-size-xs);
    }

    &__list {
      font-size: 0.7rem;
    }
  }

  @media (min-width: 1400px) {
    height: 400px;
    width: 450px;

    &__title {
      margin: 0.8rem 0;
      font-size: var(--font-size-md);
    }

    &__paragraph,
    &__list {
      font-size: var(--font-size-sm);
      margin-bottom: 12px;
    }

    &__list {
      font-size: 0.8rem;
    }
  }

  @media (min-width: 1900px) {
    height: 545px;
    width: 600px;

    &__title {
      margin: 1rem 0;
      font-size: var(--font-size-md);
    }

    &__paragraph,
    &__list {
      font-size: var(--font-size-md);
      margin-bottom: 1rem;
    }

    &__list {
      font-size: 1.1rem;
    }
  }
}
